@import "mixins";
@import "notes_attachments";


$treeShift: 27px;
$treeShiftTop: 30px;
$hPadding: 8px;

$frameWidth: 1px;
$selectionBackground: #d7efff; //#rgba(25,145,217, 0.1);
$selectionFrame: #c6e7ff;
$editBackground: white;
$editFrame: #eee;

ul.listItems {
  padding-left: $treeShift;
  margin: 0;
}

ul.listItems.hiddenCollapsed {
  display: none;
}


ul.topLevel {
  padding-top: 5px;
  padding-left: $treeShiftTop;
}

li.task {
  list-style: none;
  line-height: 21px;

  max-height: 999999px; // Prevent font-boosting, as per https://stackoverflow.com/questions/11289166/chrome-on-android-resizes-font
}

.taskOpen {
}

.taskLocal > .coreNode {
  border-left: 1px dotted #ccc;
}

.taskClosed.coreNode {
  color: #999;
  text-decoration: line-through;
}
.taskInvalid.coreNode {
  color: #999;
  font-style: italic;
}

.hide_completed .taskClosed,
.hide_completed .taskInvalid {
  display: none;
}

.assignee {
  color: $linkColor;
}

/* This div is contained within li (includes children) */
.coreNode {
  position: relative;
  padding: 6px 4px 6px $hPadding;
  border: $frameWidth solid #fff;
  border-radius: 3px;

  // We need this mostly for DnD. User can copy text if start editing it.
  @include no-user-select;

  //overflow-y: auto;   - breaks expand/collapse controls
  //transition: transform 3s ease;
}

.swipeText {
  padding: 5px 10px;
  position: absolute;

  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  background-color: #f1461f;
  color: #f0f0f0;

  overflow-x: hidden;

  white-space: nowrap;
}

.swipeText--right {
  right: 0;
  left: unset;
}

.taskContent {
  line-height: 21px;
}

.dividerClass .taskContent {
  margin-right: 0;
}

.afterTask {
  font-size: $smallText;
  line-height: 16px;

  margin-left: $itemSpace;
  display: inline-block;
}
.afterTask.tag {
  line-height: 18px;
}

.coreNode.selectedTask {
  background-color: $selectionBackground;
  border: $frameWidth solid $selectionFrame;
}

.coreNode.editingInProgress,
.coreNode.editingInProgress.selectedTask
{
  background-color: $editBackground;
  border: $frameWidth solid $editFrame;

  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  .afterTask {
    display: none;
  }

  .taskContent {
    // To make sure we dont stop editing when touch at the end of the list item
    display: block;
  }
}

//======================================================================
ul.topLevel.focusActive {

  padding-left: 8px;

  .coreNode {
    display: none;
  }

  ul.listItems.focusActive {
    .coreNode {
      display: block;
    }
  }

  ul.listItems {
    padding-left: 0;
  }

  ul.listItems.focusActive,
  ul.listItems.focusActive ul.listItems {
    padding-left: $treeShift;
  }

}
//======================================================================
.userContent {
  cursor: text;

  overflow-wrap: break-word;
}
.userContent:focus {
  outline: none;
}
//======================================================================
div.nodeImage {
  position: absolute;
  left: -35px;
  cursor: pointer;
  z-index: 10;

  width: 40px;
  top: 0;
  height: 36px;
  line-height: 32px;

  text-align: center;

  font-family: "Font Awesome 5 Pro";
  font-size: 22px;
  font-weight: 300;

  color: #ddd;
}

ul.topLevel > li > div.coreNode > div.nodeImage {
  left: -39px;
}

div.nodeImage:before {
  content: '\f107'; /* icon-caret-down */
}

div.nodeImage.nodeCollapsed:before {
  content: '\f105'; /* icon-caret-right */
}


//======================================================================
// Notes styles:
//======================================================================
.hasSomethingIcon {
  color: $linkColor;
  padding: 5px 0;
}
.hasSomethingIcon.afterTask {
  margin-left: 0;
  padding-left: $itemSpace;
}
.hasSomethingIcon:before {
  padding-right: 2px;
}

.hasSomethingIcon.hasAttachment:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f0c6"; /* fa-paperclip */
  padding-right: 4px;
}
.hasSomethingIcon.hasNote:before {
  font-family: "Font Awesome 5 Pro";
  content: "\f075"; /* fa-comment */
}

.hasSomethingIcon.hasNoteMatchedFilter {
  background-color: #ffffaa;
  border-radius: 2px;

  padding: 3px 2px;
  margin: 2px 0 2px 7px;
}
//======================================================================
// Children count icon
//======================================================================
.childrenCount {
  color: $linkColor;
  padding: 5px 0;
}
//======================================================================
// Due styles
//======================================================================
.dueDate {
  border: 1px solid #cccccc;
  color: $disabledColor;
  @include rounded;

  padding: 0 3px;
}

.dueDate.dueOverdue {
  background-color: $dueColor;
  color: white;

  border: none;
}

.dueDate.dueToday, .dueDate.dueASAP {
  color: $dueColor;
  border-color: $dueColor;
}

.dueDate.dueASAP {
  text-transform: uppercase;
}

.repeatingDue:before {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  padding-right: 4px;
  
  content: "\f021"; // fa-refresh
}

.repeatingDue.repeatingPaused:before {
  content: "\f04c"; // fa-pause
}

//======================================================================
// Drag-n-drop classes
//======================================================================
.dd-placeholder,
.dd-empty {
  margin: 0;
  padding: 0;
//  background: #f2fbff;
  border: 1px dotted #ccc;
  box-sizing: border-box;

  @include no-user-select;
}

ul.listItems.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
  margin-left: -$treeShift;

  @include no-user-select;

  > li.task > div.coreNode {
    //Added shadow on the dragged item
    border: 1px solid #86b8d6;
    box-shadow: 0px 0px 7px rgba(17,110,166, 0.5);

    border-radius: 3px;

  }
  > li.task > div.coreNode:before {
//    font-family: 'FontAwesome';
//    content: "\f047";
    color: #9fd4ee;
  }
}

//======================================================================
// Task Action Swipe Panel
//======================================================================
$panelHeight: 40px;
.actionPanel {
  position: absolute;
  height: $panelHeight;
  line-height: $panelHeight;
  background-color: #f5f5f5;

  text-align: center;

  z-index: 10;
//  width: 100px;
  width: 60px;
}

.actionIcon {
  height: $panelHeight;
  line-height: $panelHeight;
  display: inline-block;
  width: $panelHeight;
  font-size: 120%;
}



//======================================================================
// List style:
//======================================================================
@import "listStyles_mobileAndDesktop";
.checkMark {
  left: -20px;
  font-size: 17px;
}
ul li .checkMark,
ul ul li .checkMark {
  top: .3em;
}

//======================================================================
// User content styles:
//======================================================================
@import "userContent_mobileAndDesktop";
@import "userContent_mobileOnly";

.renderedImages, .task__afterContentBlock {
  width: calc(100% + 8px);
  position: relative;
  left: -8px;
}

.renderedImages {
  margin: $p_margin 0;
}
.task__afterContentBlock {
  margin-bottom: -6px;
}


.coreNode--swiping i.bulleted,
.coreNode--swiping div.nodeImage,
.coreNode--swiping .checkMark {
  display: none;
}

//======================================================================
// Color/Priority
//======================================================================
.priorityColor_1,
.priorityColor_1 a,
.priorityColor_1 a:visited
{
  color: #FF6457;
}
.priorityColor_2,
.priorityColor_2 a,
.priorityColor_2 a:visited
{
  color: #5596FF;
}
.priorityColor_3,
.priorityColor_3 a,
.priorityColor_3 a:visited
{
  color: #52C34F;
}


@mixin bgColor {
  border-radius: 3px;
}

.priorityColor_4,
.priorityColor_5,
.priorityColor_6 {
  @include bgColor;
}

.priorityColor_4 { background-color: #FF8A80;  }
.priorityColor_5 { background-color: #81B1FF; }
.priorityColor_6 { background-color: #8CDD8A; }

// Add circle marker on selected item
.selectedTask .bgMarker {
  border-radius: 10px;
  display: inline-block;

  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  left: auto;

  height: 20px;
  width: 20px;

  opacity: .7;
}
