@import "variables";

/* Mixin for Retina images, see http://37signals.com/svn/posts/3271-easy-retina-ready-images-using-scss */
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
  /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}


@mixin transform-transition {
  transition: transform 0.3s;
}

@mixin move($x, $y) {
  transform: translate3d($x, $y, 0);
}

@mixin fast-click {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  touch-action: manipulation;
}

@mixin rounded {
  border-radius: 3px;

  padding: 0 5px;
  line-height: 18px;
  display: inline-block;

  white-space: nowrap;
}

@mixin no-user-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.fixedBar {
  position: fixed;
  
  box-sizing: border-box;
  padding: 0;
  width: 100vw;
  max-width: $maxWidth;
}
