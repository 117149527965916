@import 'variables';

/**
   Content under userContent is entered by by user, and should be styled nicely.
   It includes content for tasks + content for notes
*/
/*------------Virtual url links-------------*/

$p_margin: 8px;

.userContent {

  > h1:first-child,
  > h2:first-child,
  > h3:first-child,
  > h4:first-child,
  > h5:first-child,
  > h6:first-child,
  > p:first-child {
    margin-top: 0;
  }

  > ul:last-child,
  > ol:last-child {
    display: inline-block;
  }

  > h1:last-child,
  > h2:last-child,
  > h3:last-child,
  > h4:last-child,
  > h5:last-child,
  > h6:last-child,
  > p:last-child {
    display: inline;
  }

  p, pre {
    margin: $p_margin 0;
  }

  pre {
    white-space: pre;
    font-size: 13px;
  }

  blockquote {
    margin: 1em 1em 1em 3em;
    padding: 0 0 0 1em;
    border-left: 3px solid #eee;
    font-style: italic;
  }

  a, a:visited {
    color: $text_color;

    text-decoration: none;
    text-decoration: underline\0; /* IE8, IE9 only - hack! */

    background-image: linear-gradient(to bottom, currentColor 0%, transparent 60%);
    background-repeat: repeat-x;
    background-size: 2px 2px;
    background-position: 0 100%; /* 100% for Firefox */
  }

  h1,h2, h3, h4, h5, h6 {
    margin: 10px 0;
    font-weight: 400;
  }

  h1 {font-size: 26px;}
  h2 {font-size: 24px;}
  h3 {font-size: 22px;}
  h4 {font-size: 20px;}
  h5 {font-size: 18px;}
  h6 {font-size: 16px;}


  ul, ol {
    margin-left: 25px;
    margin-top: $p_margin;
    margin-bottom: $p_margin;
    padding-left: 0;
  }

  ul:first-child:last-child,
  ol:first-child:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  ol {
    margin-left: 40px; // For a case when item starts with 2017. Some text.
  }

  ul > li {
    list-style: disc;
  }

  table {
    margin-top: $p_margin;
    margin-bottom: $p_margin;
  }

  td, th {
    padding-right: 10px;
  }

  iframe {
    margin: 5px 0;
    display: block;
  }
}

.toShorten {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  max-width: $maxLinkWidth;

  vertical-align: bottom;
}

.imageEditPopup {
  display: flex;
  font-size: 14px;
  padding: 0 5px;
}

.imageEditPopup > * {
  padding: 15px;
}

.imageEditPopup__unembed {
  border-left: 1px solid #eee;
}

.renderedImages, .task__afterContentBlock {
  background-color: white;
  width: 100%;
}

.renderedImages {
  margin: $p_margin 0;
}
.renderedImages:last-child {
  margin-bottom: 0;
}
.renderedImages:first-child {
  margin-top: 0;
}

.renderedImages img {
  display: block;
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

.task__afterContentBlock {
  line-height: 30px;
}

// When checklist's numbering is active, change numbering style to roman in markdown content
.numberedList .userContent ol > li {
  list-style: upper-roman;
}

//Markdown headings should change on select, completion, invalidation

.taskClosed .userContent a,
.task_closed .userContent a {
  text-decoration: line-through;
  color: #999;
}

.task_closed .userContent h1,
.task_closed .userContent h2,
.task_closed .userContent h3,
.task_closed .userContent h4,
.task_closed .userContent h5,
.task_closed .userContent h6,
.task_closed .userContent li {
  text-decoration: line-through;
}

.itemLink, .listLink {
  display: inline-flex;
  align-items: baseline;
}
.itemLink__link, .listLink__link {
  margin-left: 3px;
  display: inline-block;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  max-width: 600px;
}
.itemLink > i, .listLink > i {
  font-size: 75%;
  padding-left: 2px;
}
.listLink__icon {
  // Background is in the separate files for mobile/desktop
  width: 1em;
  height: 1em;
}

a.itemLink__link.task_closed {
  text-decoration: line-through;
}
a.itemLink__link.task_invalidated {
  color: gray;
  font-style: italic;
}
//end