@import "mixins";
@import "filter_field";

// Styles for the index page with lists:

.captureButton {
  height: 50%;
}

.loadingLists {
  display: flex;
  align-items: center;

  box-shadow: none;

  font-style: italic;
  height: $headerHeight;

  text-align: center;
  padding-top: 0;

  z-index: 1000;

}

.loadingLists__msg {
  flex: 1 1 auto;
}

.progressDiv {
  background-color: #019fe7;
  height: 2px;
  top: 0;
  left: 0;
  width: 0;
  z-index: 1001;
}


/*------ Information about DUE list items */
.due {
  background-color: #f5f5f5;
  border-radius: 3px;
  padding: 12px 0;
  margin-top: 10px;
  cursor: pointer;
  color: #444;
  text-shadow: 0 1px 0 #fff;
}

.due:active {
  background-color: #eee;
  position: relative;
  top: 1px;
}

.due i {
  padding: 0 5px 0 15px;

}

#dueButton {
  position: relative;
}

.dueCounter {
  position: absolute;
  top: 28px;
  right: 10px;

  background-color: $dueColor;
  border-radius: 8px;
  
  color: white;
  display: inline-block;

  font-size: 0.75rem;

  line-height: 1rem;

  padding: 0 4px;
  
  text-shadow: none;
  box-shadow: none;
}

/*-- due end */


