@import "mixins";

//-----------------------------------------------------------------
// List filtering support start:
li.hiddenByFilter {
  display: none;
}

span.hlt {
  background-color: #ffffcc;
}
// List filtering support end
