@import "mixins";

// Styles for the settings page:
.helpPage {
}

.helpContents {

  padding-left: 56px;

  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 1.3;
    margin: 2em 0 1em;
  }

  p, pre {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3;
  }

   span.attribute {
     color: #0093DF;
   }

  img.illustration {
    display: block;
    margin: 1em 0;
  }

}