@import "mixins";

.newListName {
  box-sizing: border-box;
  flex: 0 0 auto;

  width: 100%;
  line-height: 1.5;


  padding: 10px 0;
  outline: none;

  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #f0f0f0;
}


