@import "mixins";

$search_height: 40px;
$completion_item_height: 40px;

input.filter_or_search {
  display: none; // To be able to alter filter view before showing the field 
  
  -webkit-appearance: none;
  background: white none;
  
  box-sizing: border-box;

  width: 100%;
  border: 1px solid #eee;
  border-top: none;   //Removing the top border, because of the toolbar's bottom border
  outline: none;
  padding-left: 56px;
  padding-right: 45px;

  line-height: normal;
  height: $search_height;
}

input.filter_or_search.filtered {
  background-color: #ffffe0;
}
input.filter_or_search.focused {
  box-shadow: 0 2px 1px 0 rgba(240,240,240,0.75);
  transition: box-shadow 0.2s;
}

.filter_wrapper.positionAbsolute {
  position: absolute;
}

.filter_wrapper {
  @extend .fixedBar;
  
  // should be less than .menuBar z-Index 
  z-index: 11; 

  position: fixed;
  top: $headerHeight;
  
  background-color: white;
  

  .clear_filter, .search_icon {
    z-index: 10;
    position: absolute;

    color: #ddd;
  }

  .search_icon {
    top: 12px;
    left: 20px;
  }
  .clear_filter {
    top: 0;
    right: 0;
    cursor: pointer;
    width: 36px;
    height: $search_height;
    line-height: $search_height;
  }
}

.completerContainer {
  position: absolute;
  top: $search_height;
  right: 0;
  left: 0;

  background-color: #f5f5f5;


  visibility: hidden;
  transition: transform 0.2s;
  transform: translate3d(0, -105%, 0);

  z-index: -1;

  max-height: 50vh;
  overflow-y: auto;

  border-bottom: 1px solid #eee;
}

.completerContainer.shown {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

a.completer_cancel {
  float: right;
  color: $disabledColor;

  display: none;
}

.vertical .completer_cancel {
  display: block;
}

// Completion component under the filter line:
.completerItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

a.completionEl {
    height: $completion_item_height; 
    line-height: $completion_item_height;
    padding: 0 10px;
    flex-grow: 1;
    flex-shrink: 0;
    white-space: nowrap;
    
    text-align: center;
    border-right: 1px solid #eee;
}

.completerContainer.vertical .completerItems {
  flex-direction: column;
  flex-wrap: nowrap;
  
  a.completionEl {
    padding: 0 20px;
    
    border: none;
    text-align: left;
  }
}

.filteredArea {
  transition: transform 0.2s;
  transform: translate3d(0, 0, 0);
}

.withCompleter .filteredArea {
  transform: translate3d(0, $completion_item_height, 0);
}

