@import "task_tree";
@import "filter_field";
@import 'noty';

.addListItemHidden {
  // Enabled via Javascript
  display: none;
}


//======================================================================
// Task Toolbar
//======================================================================
.task_toolbar {

  height: $headerHeight;

  // Flex layout, center buttons
  display: flex;

  .iconButton {
    width: $buttonWidth;
    flex-grow: 4;
  }
  .iconButton.margin {
    flex-grow: 1;
  }

}

.taskFocus.focusActive .fa-thumbtack {
  color: #f1461f;
  font-weight: bold;
}




.taskAddMenu,
.taskMoreMenu,
.priorityMenu {
  bottom: $footerHeight;

  @include move(0, 102%);

  z-index: 10;
}

.priorityMenu {

  @include move(0, 100%);

  padding: 10px;
}

.priorityMenu__grid {
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
}

.priorityMenu__grid .slidingMenu__item {
  border: none;
  text-align: center;
  padding: 0;
  border: $lightBorder;

  line-height: 50px;
  width: 50px;
}
.priorityMenu__0 {
  margin-top: -10px;
}

.addListItem--shown i {
  transform: rotate(45grad);
}
.addListItem i {
  transition: transform 300ms ease;
}


ul.breadcrumbs {
  margin: 0;
  padding: 8px 16px 8px 16px;
  background-color: $headerColor;
  
  display: flex;
  flex-wrap: wrap;
  
  li.task_breadcrumb {
    
    line-height: 1.5;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    
    
    > a {
      color: $buttonColor;
    }

    > a:after {
      padding: 0 8px 0 8px;
      content: ">";
      font-weight: 300;
    }
  }

}

