/*****************************************************************************************************************/
/*****************************************************************************************************************/
/*                        Checkvist app styles                                                                   */
/*****************************************************************************************************************/
/*****************************************************************************************************************/

@import "boilerplate";
@import "mixins";
@import "normalize";

$fa-font-path:                "../../node_modules/@fortawesome/fontawesome-pro/webfonts" !default;

@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light";

@import "page_main";
@import "tags";
@import "messages";
@import "filtering_support";
@import "list_of_lists";

//@import "intro_page";
@import "signIn_page";
@import "due_page";
@import "lists_page";
@import "list_page";
@import "settings_page";
@import "help_page";
@import "bookmarklet_page";
@import "add_list_page";


// Override for qunit-tests:
#qunit-header { margin-top: 3em; }
