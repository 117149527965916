
/*-------- Checkboxed lists */

.checkMark {
  font-family: "Font Awesome 5 Pro";
  font-weight: normal;
  font-style: normal;
  text-decoration: initial;
  -webkit-font-smoothing: antialiased;

  display: none;
  color: #c0c0c0;
  position: absolute;
  left: -16px;

  z-index: 5;
}

/*--------- Vertical alignment for different levels*/

ul li .checkMark {
  top: .47em;
}

@media screen {
  .header1 .checkMark { top: 15px; }
  .header2 .checkMark { top: 13px; }
  .header3 .checkMark { top: 12px; }
  .header4 .checkMark { top: 10px; }
  .header5 .checkMark { top: 9px; }
  .header6 .checkMark { top: 8px; }
}


.checkMark:before {
  content: "\f0c8";
}

.task_closed .checkMark:before,  /* desktop app */
.taskClosed .checkMark:before    /* mobile app */
{
  content: "\f14a";
}

.boxesList .checkMark,
.boxesList .boxesList .checkMark
{
  display: block;
}

/*-------- Bulleted lists */

.bulleted {
  display: none;

  font-size: 5px;
  color: #c0c0c0;
  position: absolute;
  left: -14px;
  margin-top: 11px;
}

ul ul .bulleted {
  margin-top: 10px;
}

.bulletsList .bulleted,
.bulletsList .bulletsList .bulleted
{
  display: block;
}

// Markdown headings for the list view
.header1 .bulleted { margin-top: 17px;}
.header2 .bulleted { margin-top: 15px;}
.header3 .bulleted { margin-top: 14px;}
.header4 .bulleted { margin-top: 13px;}
.header5 .bulleted { margin-top: 12px;}
.header6 .bulleted { margin-top: 12px;}

/*-------- CSS numbering for the task lists and the print view */
.numberedList ul {
  counter-reset: section;
}

.numberedList li.nonDivider {
  counter-increment: section;
}

.numberedList ul span.taskContent:before {
  content: counters(section, ".") ". ";
}


.noHeaders .userContent {
  h1, h2, h3, h4, h5, h6 {
    font-weight: inherit; // 300 on mobile, 400 on desktop
    font-size: 1rem;
  }
}

/*-------- Mix of styles lists */
.boxesList .bulletsList .checkMark,
//.boxesList .numberedList .checkMark,
.bulletsList .boxesList .bulleted,
.bulletsList .numberedList .bulleted
{
  display: none;
}
//.numberedList .boxesList ul span.taskContent:before,
.numberedList .bulletsList ul span.taskContent:before
{
  content: none;
}

