@import "variables";

.notesAttachments {
  background-color: white;

  border-left: $lightBorder;
  border-bottom: $lightBorder;

  display: flex;
  flex-direction: column;

  position: fixed;
  right: 0;
  top: 0;

  height: 750px;
  width: 450px;
  max-height: 100vh;
  max-width: 100vw;

  z-index: 45; // Should be more than for #offline_icon

  @include move(100%, 0);
}

.noteMenu, .saveCancelMenu {
  z-index: 48; // Should be above .notesAttachments value
}

.notesAttachments--smooth {
  @include transform-transition;
}

.notesAttachments--shown {
  @include move(0, 0);
}

.notesAttachments__body {
  padding-left: $mainPadding;
  padding-right: $mainPadding;

  overflow: auto;

  flex: 1 1 auto;
}

.notesAttachments .windowTitle {
  position: static;
  padding: 0 $mainPadding;
  z-index: 1;
}

.notesAttachments__footer {
  background-color: $footerColor;
  border-top: $lightBorder;

  padding-left: $mainPadding;
  padding-right: $mainPadding;

  line-height: $footerHeight;
}

.notesAttachments--editing .notesAttachments__footer,
.notesAttachments--editing .notesAttachments__header {
  display: none;
}

.notesAttachments__addNote i {
  padding-right: 6px;
}




ul.comments, .uploadList {
  padding: 20px 0 0 0;
  margin-top: 0;

  list-style-type: none;
}

.uploadList {
  border-top: $lightBorder;
}

.uploadList:first-child {
  border-top: none;
}

li.comment, .upload {
  display: flex;

  line-height: 20px;

  padding: 7px 0;
}

li.comment, .uploadList__upload {
  margin-bottom: 10px;
}


.comment__details, .upload__details, .upload__user {
  color: #888;
  display: block;
}

.comment--editing .comment__details {
  display: none;
}

.comment__details, .comment__info .userContent {
  padding: 2px 4px;
}

.comment__gravatar {
  margin-top: 3px;
  margin-right: 15px;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.comment__info {
  flex: auto;
  min-width: 50%;
}

.comment__info .userContent {
  display: block;
  border: 1px solid white;
}

.comment--editing .userContent {
  border: $lightBorder;
  box-shadow: 0 0 2px 0 #fafafa;
}

.comment__menu {
  flex: 0 0 auto;
  color: #555;
  display: inline-block;
  font-size: 1.2rem;

  cursor: pointer;

  height: 50px;
  width: 40px;

  margin-top: -10px;
  padding-top: 10px;

  text-align: center;
}

.comment--editing .comment__menu {
  color: $linkColor;
  font-size: 1.5rem;
}

.comment__menu--hidden {
  visibility: hidden;
}

.comment__gravatarImage {
  width: 28px;
  height: 28px;
  display: inline-block;
  background-size: 28px 28px;
  background-repeat: no-repeat;

  border-radius: 14px;
}


.upload__icon {
  font-size: 1.5rem;
  margin-right: 15px;
  width: 22px;
}

a.upload__link {
  display: inline-block;
  color: $text_color;
  text-decoration: underline;

  margin-bottom: 10px;
}

.upload__image_view {
  display: block;

  left: -16px;
  margin: 5px 0 15px 0;
  position: relative;

  width: calc(100% + 32px);

  text-align: center;

  .upload__image {
    image-rendering: -webkit-optimize-contrast;
    max-width: 100%;
  }
}

.upload__preview_message {
  background-color: #f0f0f0;

  font-style: italic;
  padding: 10px 10px 10px 36px;
}

.upload__preview_message--error {
  color: #bc2e22;
}

