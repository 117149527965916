$maxWidth: 1030px;

$headerColor: #fafafa;
$footerColor: #fafafa;
$lightBorder: 1px solid #f0f0f0;

$headerHeight: 50px;
$footerHeight: 50px;
$headerWithFilter: 90px;
$headerFooterZ: 20;

$buttonSize: 50px;
$buttonWidth: 56px;
$buttonColor: #0093DF;
$buttonColorNew: #0070F7;
$footerHeight: 50px;

//$linkColor: #1991d9;
$linkColor: #0093DF;
$linkColorNew: #0070F7;
$activeLinkColor: #1474ad;
$dueColor: #F1451F;
$disabledColor: #888;
$itemSpace: 10px;

$smallText: .85rem;

$mainPadding: 16px;
$bottomShadow: 0 3px 5px #ccc;

$text_color: #222;   // Used in userContent_mobileAndDesktop.scss
$maxLinkWidth: 80vw; // Used in userContent_mobileAndDesktop.scss
