@import "mixins";

//-----------------------------------------------------------------
// List selector section, used in filters as well
ul.homeList {
  margin: 0;
  padding: 0;
}

ul.homeList:last-child {
  margin-bottom: 40px;
}

ul.homeList li, ul.listsMenu li {
  list-style: none;
  padding: 12px 0;
  line-height: 1.4;
  border-bottom: 1px solid #f3f3f3;
  color: $text_color;
  clear: both;

  cursor: pointer;
}


ul.homeList li.noData {
  color: $disabledColor;
}

ul.homeList li.listLocal:after{
  content: "offline";
  border-left: 1px dotted #ccc;
  font-style: italic;
  margin-left: 5px;
  padding-left: 5px;
}

ul.homeList li:active, ul.listsMenu li:active {
  background-color: #fcfcfc;
}

.readOnly {
  display: inline-block;
  margin-left: $itemSpace;
  font-style: italic;
  color: $disabledColor;
}


.title .readOnly {
  margin-right: 5px;
  margin-left: 0;
}
