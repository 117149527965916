@import "mixins";

/** When a single message should be shown on the page, like "Cannot load lists" */
.singlePageMessage {
  padding-left: 56px;
  margin-top: 10px;
}

// Generic styles for ALL "loading .." messages
.loading {
  font-style: italic;
}


// Error message when cannot login (and on some other errors)
.errorMessage {
  box-sizing: border-box;

  width: 100%;
  margin: 0 auto;

  text-align: center;
  line-height: 100%;

  background-color: #bc2e22;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, .3);

  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transform-origin: top center;
  transform: scaleY(0);
}

.errorMessage--info {
  background-color: #0070F7;
}

.errorMessage--visible {
  transform: scaleY(1);
  padding: 10px;
}




@-webkit-keyframes rotate {
  0% { -webkit-transform: rotate(0deg) }
  100% { -webkit-transform: rotate(360deg)}
}
@keyframes rotate {
  0% { transform: rotate(0deg)}
  100% { transform: rotate(360deg)}
}

.rotate {
  animation: rotate 2s linear infinite;
  -webkit-animation: rotate 2s linear infinite;
}

#offline_icon {
  display: none;

  z-index: 40;


  i {
    line-height: 100%;
    height: 100%;
    width: 100%;
    text-align: center;

    color: $dueColor;
  }
}
