@import "mixins";

// Styles for the settings page:
.settingsPage {
}

.settings dt {
  margin: 10px 0 5px 0;
  color: #676767;
}

.settings dd {
  color: $text_color;
}


.settings dt i {
  display: inline-block;
  margin-left: 10px;
}


.settings dd {
  margin: 5px 0 20px 0;
  font-weight: 500;
}

.settings__proIcon {
  color: $dueColor;
}

i.settings__toDark {
  color: #676767;
}



.settingsLink {
  display: block;
  margin: 0;
  padding: 10px 0;
}

.viewLog {
  margin: 50px auto 0 auto;
  text-align: center;
}

.settingsContents {


  hr {
    border-top: $lightBorder;
    margin: 15px 0;
  }

  i {
    width: 15px;
  }

}

.settingsContents {
  padding: 0 24px;
}

.settings__footer {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  max-width: 250px;
  margin: 0 auto;
}

.settings__footer > * {
  flex: 1 1 100%;
}


.debugSession--on i.fa-bug {
  font-weight: bold;
}

.debugSession__instructions {
  display: none;
  margin: 10px 0;
}

.debugSession__cancel {
  margin: 20px 0;
  display: block;
  text-align: center;
}

.debugSession--on .debugSession__instructions {
  display: block;
  font-size: 16px;
  line-height: 1.2;
  color: #676767;
}

.debugSession__sendLogs {
  display: block;
  margin: 20px auto;
  width: 100%;
  max-width: 250px;
}

.addHomeScreen {
  margin-bottom: 20px;
}