@import "mixins";

//-----------------------------------------------------------------
// Tags presentation
.tag {
  background-color: #eee;
  color: $linkColor;
  font-size: $smallText;

  @include rounded;
}

.tag:before {
  content: "";
}

ul.homeList .tag {
  float: right;
  margin: 4px 0 0 10px;
}
