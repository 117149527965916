@import "mixins";

nav.tabs {
  display: flex;
  background-color: $headerColor;
  
  margin-bottom: 10px;
  box-shadow: $bottomShadow;
}

nav.tabs a {
  flex-grow: 1;
  text-align: center;
  padding: 10px;
}

nav.tabs a.selectedTab {
  border-bottom: 3px solid $dueColor;
  color: #222;
}

.tabs .counter {
  padding-left: 10px;
  font-size: $smallText;
  color: $disabledColor;
}

.dueItems.topLevel {
  padding-left: 0;
}

.dueItems ul.listItems {
  padding-left: 36px;
}

.dueItems .dueASAP {
  display: none;
}

li.taskGroup {
  list-style: none;
}

.taskGroupTitle {
  border-bottom: $lightBorder;
  padding: 10px 0 5px 0;
  margin-bottom: 5px;
}

