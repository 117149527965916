@import "mixins";

.signinForm {
  width: 70%;
  min-width: 300px;
  margin: 3% auto 0;
  text-align: center;
}

.logo {
  margin-bottom: 30px;
}

.signinForm table {
  width: 100%;
  margin: 15px 0;
  border-collapse: collapse;
}

.signinForm th, .signinForm td {
  border: 1px solid #ccc;
}

.token2fa th, .token2fa td {
  border-color: $buttonColorNew;
}

.signinForm th {
  width: 25px;
  border-right: none;
}

.signinForm td {
  border-left: none;
}

.signinForm .buttonPrimary {
  background-color: $buttonColorNew;
}

.fieldIcon {
  display: inline-block;
  font-size: 20px;
  color: #aaa;
  border-right: 1px solid #ccc;
  padding: 8px 10px;
}

.signinForm__field {
  box-sizing: border-box;

  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  padding: 8px 10px;
  width: 100%;

  -moz-appearance:none;
}

a.forgot {
  display: block;
  margin: 35px 0;

  color: $linkColorNew;
}

.signinForm .buttonPrimary {
  margin-top: 20px;
  min-width: 200px;
  display: block;
}

.token2fa {
  display: none;
}
.token2fa--visible {
  display: table;
}