@import "mixins";

.subjectLine {
  height: 50px;
  line-height: 50px;
  margin-top: 2px;
  
  display: flex;
}

.attach {
  line-height: 50px;
  font-size: 22px;
}

.addToLabel {
  padding-right: 5px;
  white-space: nowrap;
  flex-shrink: 1;
}

.addNote {
  box-sizing: border-box;

  width: 100%;
  min-height: 50px;

  outline: none;
  padding: 15px 55px 15px 0;
  line-height: 1.5;
  border: none;

  flex: 1 1 auto;

  resize: none;

  margin-bottom: 15px;
}

.addNote__hintCloser {
  color: $linkColor;
  cursor: pointer;

  flex: 0 0 0;
  position: relative;

  font-size: 20px;

  text-align: right;
}

.addNote__hintCloser > * {
  position: absolute;
  right: -10px;
  //top: -45px;

  padding: 15px;
}

.addNote__hintIcon {
  font-size: 22px;
  top: -55px;
  right: 2px;
}

.addNote__hint {
  background-color: $linkColor;
  border-radius: 3px;
  box-sizing: border-box;
  color: #fff;

  flex: 0 0 auto;

  margin-left: 0;
  margin-right: 0;
  margin-bottom: $mainPadding;

  padding: $mainPadding 10px;

  transition: transform 0.4s;
  @include move(0, 180%);
}
.addNote__hint.slidingPanel--shown {
  @include move(0, 60%);
}

.addNote__hint p {
  margin: 0;
  line-height: 1.3;
}


#bookmarklet .buttonLight {
  width: 100%;
  box-sizing: border-box;
}

.photos {
  padding: 10px;
}

span.upload-placeholder {
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
  cursor: pointer;

  img, i {
    width: 44px;
    height: 44px;
    border-radius: 3px;
    padding: 10px;
    background-color: #f3f3f3;
  }

  i {
    color: #555;
    display: inline-block;
    font-size: 44px;
    text-align: center;
  }

  img:active {
    background-color: #eee;
    position: relative;
    top: 1px;
  }

}

.attachContainer {
  position: absolute;
  top: 60px;
  right: 10px;
  font-size: 20px;
  z-index: 50;
}

.attachLink {
  line-height: $buttonSize;
  height: $buttonSize;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  background-color: white;
  display: block;
}

.addPhotoControl {
  text-align: center;

  width: $buttonWidth;

  a {
    position: relative;

  }

  input[type=file] {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    width: 50px;

    font-family: Arial;
    font-size: 50px;

    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
  }
}

#checklist_id_text {
  background-color: transparent;
  box-shadow: none;
  outline: none;

  max-width: calc(100vw - 170px);

  overflow-y: hidden;
  text-overflow: ellipsis;
}

.listChooser {
  box-sizing: border-box;

  font-family: "Helvetica Neue", sans-serif;

  color: $linkColor;

  border: none;

}

.listChooserContainer {
  color: $linkColor;
  flex-grow: 1;
  flex-shrink: 1;
}


header.listSelector {
  z-index: 200;
}

.listSelectorSection {
    position: absolute;
    top: 1000px;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    background-color: white;
    z-index: 100;

    @include transform-transition;
    @include move(0, 0);

}

.listSelectorSection.slidingPanel--shown {
  @include move(0, -100%);
}

.listSelectorSection .main {
    padding-top: $headerWithFilter;
}

button.cancel {
  padding: 0;
}

