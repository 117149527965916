@import "mixins";

$fullHeight: 100vh;

a, a:visited {
  color: $linkColor;
  text-decoration: none;
}

a:active {
  color: $activeLinkColor;
}

html, body {
  padding: 0;
  margin: 0;
  min-height: $fullHeight;

  font-family: "Helvetica Neue", helvetica, sans-serif;
  color: $text_color;

  // Disable flickering on iOS when a page is touched
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  //  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

:root {
  color-scheme: light;
}

.mainPadding {
  padding-left: $mainPadding;
  padding-right: $mainPadding;
}


/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f5f5f5;
  margin: 5px 0;
  padding: 0;

  @include no-user-select;
}


main.container {
  min-width: 320px;
  max-width: $maxWidth;
  min-height: $fullHeight;

  margin: 0 auto;
  
  position: relative; // To correctly position bottom task bar on list page on selection with scrolling
}

.fullHeight {
  // could be set to the main.container element as well, via JS, see page.js
  min-height: $fullHeight;
}

.mainContainer {
  box-sizing: border-box;
  min-height: $fullHeight;

  padding-top: $headerHeight;
  padding-bottom: $headerHeight;  // Should be padding to avoid extra scrolling of mainContainer when a page is filtered and focused and not all items fits the screen

  position: relative; // For correct positioning of "you're offline" message
  overflow-y: auto; // This fixes case when a list item is verylongwithoutspaces - without it, bottom bar goes off the page
}
.mainContainer.withFilter {
  padding-top: $headerWithFilter;
}

.mainContainer.withFilter.withFilterNoHeader {
  padding-top: 40px;
}

.positionFixed {
  position: fixed;
}

.positionAbsolute, .positionFixed.positionAbsolute {
  position: absolute;
}

.topBarStyles {
  background-color: $headerColor;
  border-bottom: $lightBorder;

  height: $headerHeight;

  display: flex;
  align-items: center;

  .iconButton {
    flex: 0 0 auto;

    background-color: $headerColor;
    height: 49px;
  }
}

header.topBar {
  @extend .fixedBar;
  @extend .topBarStyles;

  z-index: $headerFooterZ;

  top: 0;

  text-align: center;

}

// Generic footer
footer.bottomPanel {
  @extend .fixedBar;
  z-index: $headerFooterZ;

  bottom: 0;

  color: #fff;
  text-shadow: 0 1px 0 #02659a;
  background-color: $linkColor;

  border-top: 1px solid #f0f0f0;
  border-radius: 20px 20px 0 0;

  height: 60px;
  line-height: 60px;

  text-align: center;

  cursor: pointer;
}

footer.bottomPanel:active {
  background-color: #02659a;
  @include move(1px, 1px);
}

footer.generic {
  margin-top: 30px;
}







/*--- Title bar */

.iconButton {
  color: $buttonColor;
  font-size: 22px;
  height: $buttonSize;
  line-height: $buttonSize;
  padding: 0 $mainPadding;
  text-align: center;
  width: $buttonWidth;

  box-sizing: border-box;
  @include fast-click;
}

.iconButton:hover {
  cursor: pointer;
}

.iconButton.cancel_back {
  text-align: left;
}

.windowTitle {
  font-size: 1rem;
  font-weight: normal;
  line-height: $headerHeight;
  text-align: left;
  margin: 0;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 0;

  z-index: -1;

  // On iOS DnD of list items results in text selection in the title :-o
  @include no-user-select;
}

.title {
  color: #676767;
}

.iconButton .fa-cogs {
  font-size: 20px;
}

h3.message {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
  padding: 10px;
  text-align: center;
}


.right {
  float: right;
}

.left {
  float: left;
}

.middle {
  vertical-align: middle;
}

.smallIcon {
  font-size: 14px;
}


.menuBar {
  box-sizing: border-box;

  width: 100vw;
  max-width: $maxWidth;

  @include transform-transition;
  @include no-user-select;

  //  Should be less than z-index for header and footer, but more than z-index of collapse/expand node.
  z-index: 15;
}

.bottomMenu {
  background-color: $footerColor;
  bottom: 0;
  @include move(0, 102%);
}


.topMenu {
  background-color: white;

  margin-top: -1px;
  margin-bottom: 20px;
  box-shadow: $bottomShadow;

  top: 0;
  padding-top: $headerHeight;

  @include move(0, -100%);
}

.topMenu.topMessage {
  background-color: $headerColor;
}

.topMenu .readOnly {
  text-align: center;
  width: 100%;
  color: $disabledColor;
}

.topMenu ul.listsMenu {
  box-sizing: border-box;
  margin: 10px;
  padding: 0;
}

.topMenu ul.listsMenu li i {
  display: inline-block;

  visibility: hidden;
  width: 40px;
  padding-left: 11px;
}

.topMenu ul.listsMenu li.activeListItem i {
  visibility: visible;
}

.topMenu ul li:last-child {
  border: none;
}

.topMenu .buttonLight {
  margin-left: 38px;
}

/* ----- buttons */

.btn {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;

  font-family: "Helvetica Neue", sans-serif;

  padding: 8px 12px;

  @include fast-click;
}

.btn:active {
  @include move(1px, 1px);
}

.buttonPrimary {
  padding: 10px 20px;

  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  min-height: 28px;
  text-shadow: 0 1px 0 #02659a;
  background-color: $buttonColor;
  border: none;
}

.buttonPrimary:active {
  background-color: #02659a;
}

.buttonPrimary.buttonCenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.buttonLight {
  border: 1px solid #eee;
  background-color: #fafafa;

  font-size: 1rem;
  font-weight: 500;
}

.buttonLight:active {
  background-color: #eee;
}

.buttonToolbar {
  padding: 8px 12px;
  margin-right: 10px;

  border: none;
  background-color: #fafafa;
}

.buttonToolbar.buttonPrimary {
  color: #fff;
  text-shadow: 0 1px 0 #02659a;
  background-color: $linkColor;
  border: none;
}

::-webkit-input-placeholder {
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1rem;
  color: #777;
}


.flexVerticalPage {
  display: flex;
  flex-direction: column;

  height: calc(100vh - 100px);
}


.noty1_type_checkvist {
  background-color: white;
  border-color: #0D3349;
}
.noty1_buttons {
  border-top: 1px solid #ccc;
  padding: 20px 10px;
}
.noty1_message {
  padding: 20px;
}


/*---- the very first screen */

.logo {
  padding-top: 13vh;
  width: 75%;
  margin: 0 auto;

  box-sizing: border-box;
}

.logo img {
  width: 100%;
}

/*----- The Recent list and Capture page (home) */

/* Generic sliding panel */
.slidingPanel {
  box-sizing: border-box;
  @include transform-transition();
}

.slidingPanel.slidingPanel--hidden, .slidingPanel--hidden {
  display: none;
}

.slidingPanel.slidingPanel--shown, .slidingPanel--shown {
  @include move(0, 0);
}

/* Generic sliding menu */
.slidingMenu {
  background-color: white;
  border: $lightBorder;
  border-radius: 2px;
  box-shadow: 0 0 2px 1px #f0f0f0;

  min-width: 150px;

  padding-bottom: 8px;
  padding-top: 8px;
}

.slidingMenu__item {
  padding-left: 30px;
  padding-right: 30px;

  cursor: pointer;
  font-size: 1rem;
  line-height: 58px;

  touch-action: none;

  border-top: $lightBorder;
}

.slidingMenu__item--disabled {
  color: $disabledColor;
}

.slidingMenu__item--first,
.slidingMenu__item:first-child {
  border-top: none;
}

.slidingMenu__item i {
  width: 25px;
}


